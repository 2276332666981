



















































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import { Setting, Tier } from '@/domain/entities/Payroll'
import { LocalStorage } from '@/app/infrastructures/misc'
import PayrollTextInput from '../../../components/PayrollTextInput/index.vue'
import {
  shipmentPrefixOptions,
  sttPrefixOptions,
  codMultiplyOptions,
} from '@/app/infrastructures/misc/Constants/manageDataMaster'
import DropdownMultiSelect from '../../../components/DropdownMultiSelect/index.vue'
import WarningCircleIcon from '@/app/ui/assets/ics_f_warning_circle_red.vue'
import CODFeeInput from '../../../components/CODFeeInput/index.vue'

interface Options {
  label: string
  value: string
}

interface IRule {
  [k: string]: Options | ISetting[] | undefined
}

interface ISetting {
  isAllPrefix: boolean
  isDefault: boolean
  prefix: string[]
  setting: ITier[]
}

interface ITier {
  minWeight: number
  multiplyType?: Options
  fee: number
  parkingFee: number
  codMultiplyType?: Options
  codFee: number
}

interface PeriodRule {
  basicFee: {
    calculationMethod: Array<string>
    productType: Array<string>
    multiplyType: Array<string>
    rules: Array<Record<string, string | Array<Tier>>>
  }
  basicFeeId: number
}

@Component({
  components: {
    DropdownSelect,
    Loading,
    PayrollTextInput,
    DropdownMultiSelect,
    CODFeeInput,
    WarningCircleIcon,
  },
})
export default class BasicFeePage extends Vue {
  controller = controller
  currentPeriod = ''
  basicFeeId = ''
  modalConfirmVisible = false
  modalConfirmDeleteVisible = false
  modalSuccessVisible = false
  isValidate = false
  selectedRuleIndex = 0

  calcMethodOptions: Array<Options> = []
  productOptions: Array<Options> = []
  multiplyOptions: Array<Options> = []

  ruleData: Array<IRule> = []

  shipmentPrefixOptions = shipmentPrefixOptions
  sttPrefixOptions = sttPrefixOptions
  codMultipleOptions = codMultiplyOptions

  created(): void {
    this.currentPeriod = LocalStorage.getLocalStorage('selectedPeriod')
    this.fetchData()
  }

  get filteredProductTypeOpt(): Array<Options> {
    const selectedProduct = this.ruleData.map(rd => rd.productType)
    return this.productOptions.filter(cm => !selectedProduct.includes(cm))
  }

  private getHeaders(tierType: string) {
    const headers = ['Berat Minimal', 'Type (KG/FLAT)']

    if (tierType === 'pickup') {
      headers.push('Biaya Pick Up')
      headers.push('Biaya Pick Up - Parkir')
      headers.push('Biaya COD')
    } else if (tierType === 'delivery') {
      headers.push('Biaya Delivery')
      headers.push('Biaya Delivery - Parkir')
      headers.push('Biaya COD')
    }

    return headers
  }

  private fetchData() {
    controller.getDetail({
      id: this.$route.params.periodId,
      page: parseInt(<string>this.$route.params.page),
    })
  }

  private generateOptions(list: string[], labelFormat: string) {
    const res = list.map(method => {
      let label = method

      if (labelFormat === 'capitalize')
        label = `${label.charAt(0).toUpperCase()}${label.substring(1)}`
      if (labelFormat === 'uppercase') label = label.toUpperCase()

      return {
        label: label,
        value: method,
      }
    })

    return res
  }

  private filterShipmentPrefixOptions(
    ruleIndex: number,
    prefixes: string[]
  ): Options[] {
    const selectedPrefix = (<ISetting[]>(
      this.ruleData[ruleIndex].pickupTier
    )).map(tier => tier.prefix.map(prefix => prefix))
    let mergedPrefix: string[] = []

    selectedPrefix.forEach(prefix => {
      prefix.forEach(prefixString => {
        mergedPrefix.push(prefixString)
      })
    })

    mergedPrefix = mergedPrefix.filter(prefix => !prefixes.includes(prefix))

    const finalSelectedPrefix = this.shipmentPrefixOptions.filter(option => {
      return !mergedPrefix.includes(option.label)
    })

    return finalSelectedPrefix
  }

  private filterSTTPrefixOptions(
    ruleIndex: number,
    prefixes: string[]
  ): Options[] {
    const selectedPrefix = (<ISetting[]>(
      this.ruleData[ruleIndex].deliveryTier
    )).map(tier => tier.prefix.map(prefix => prefix))
    let mergedPrefix: string[] = []

    selectedPrefix.forEach(prefix => {
      prefix.forEach(prefixString => {
        mergedPrefix.push(prefixString)
      })
    })

    mergedPrefix = mergedPrefix.filter(prefix => !prefixes.includes(prefix))

    const finalSelectedPrefix = this.sttPrefixOptions.filter(option => {
      return !mergedPrefix.includes(option.label)
    })

    finalSelectedPrefix

    return finalSelectedPrefix
  }

  private checkIsSelectAllPrefix(
    prefixes: string[],
    option: Options[]
  ): boolean {
    return prefixes.length === option.length
  }

  private tierMapper(settingData: Setting[], isPickupTier = false): ISetting[] {
    return settingData.map((sd, index) => {
      return {
        isDefault: index === 0,
        prefix: sd.prefix?.map(prefix =>
          isPickupTier
            ? this.shipmentPrefixOptions.find(option => option.value === prefix)
                ?.label
            : this.sttPrefixOptions.find(option => option.value === prefix)
                ?.label
        ),
        setting: sd.setting?.map(tier => {
          return {
            fee: tier.fee || 0,
            minWeight: tier.minWeight || 0,
            parkingFee: tier.parkingFee || 0,
            multiplyType:
              this.multiplyOptions.find(
                opt => opt.value === tier.multiplyType
              ) || {},
            codMultiplyType:
              this.codMultipleOptions.find(
                opt => opt.value === tier.codMultiplyType
              ) || this.codMultipleOptions[0],
            codFee: tier.codFee || 0,
          }
        }),
      }
    }) as ISetting[]
  }

  @Watch('controller.dataPeriodRule')
  setDataPeriodRule(data: PeriodRule): void {
    this.ruleData = []
    const basicFee = data.basicFee

    if (data.basicFeeId) {
      this.basicFeeId = String(data.basicFeeId)
    }

    if (basicFee?.calculationMethod) {
      this.calcMethodOptions = this.generateOptions(
        basicFee?.calculationMethod,
        'capitalize'
      )
    }

    if (basicFee?.productType) {
      this.productOptions = this.generateOptions(
        basicFee?.productType,
        'capitalize'
      )
    }

    if (basicFee?.multiplyType) {
      this.multiplyOptions = this.generateOptions(
        basicFee?.multiplyType,
        'uppercase'
      )
    }

    if (basicFee?.rules) {
      const basicFeeRules = basicFee.rules as Array<
        Record<string, string | Array<Tier>>
      >

      basicFeeRules.forEach(rule => {
        const deliveryTier = this.tierMapper(<Setting[]>rule.deliveryTier)
        const pickupTier = this.tierMapper(<Setting[]>rule.pickupTier, true)

        const res: IRule = {
          calculationMethod: this.calcMethodOptions.find(
            opt => opt.value === rule.calculationMethod
          ),
          deliveryTier: deliveryTier,
          pickupTier: pickupTier,
          productType:
            this.productOptions.find(opt => opt.value === rule.productType) ||
            <Options>{},
        }
        this.ruleData.push(res)
      })
    }
  }

  @Watch('controller.isEditPeriodBasicFeeSuccess')
  setEditPeriodBasicFeeSuccess(data: boolean): void {
    if (data) {
      this.modalSuccessVisible = true
      this.fetchData()
      controller.setIsChanged(false)
    }
  }
}
